import Swal from 'sweetalert2';
import React, { Fragment } from 'react';
import { ListItem } from 'reactstrap';
import '../../assets/css/loginCaptura.css'




export const useSweetAlert = () => {

   

    const createSweet = (method, status, title = "", subtitle = "") => {

        if (title.length <= 0) {

            if (method === "create") {
                title = "Creación exitosa";
            }
            else if (method === "update") {
                title = "Actualización exitosa";
            }
            else if (method === "error") {
                title = "Ocurrió un error";
            }

            else {
                title = "Eliminación exitosa";
            }
        }

        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 7000,
            timerProgressBar: false,
            showClass: {
                popup: 'animate__animated animate__fadeInRight'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutRight'
            },
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: status,
            title: title,
            text: subtitle,
        })
    }

    const createSweett = (title = "", subtitle = "", icon="") => {
        Swal.fire({
            title: title,
            text: subtitle,
            icon: icon,
            showClass: {
                popup: 'animate__animated animate__fadeInDown',
                icon: 'animate__animated animate__bounce' 
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
                icon: 'animate__animated animate__bounceOut' 
            },
            customClass: {
                icon: 'my-custom-icon-class' 
            },
        })
    }

    const sweetConfirmation = async (title, subtitle, icon, cancelBtn = false, method) => {

        Swal.fire({
            title: title,
            text: subtitle,
            icon: icon,
            showCancelButton: cancelBtn,
            confirmButtonText: "El cambio es correcto",
            cancelButtonText: "Corregir la cantidad",
            allowEscapeKey: false,
            allowOutsideClick: false
        })
            .then((result) => {
                if (result.isConfirmed) {
                    method(true);
                }
                else {
                    method(false);
                }
            })
    }

    const sweetConfirmationCancel = async (title, subtitle, icon, cancelBtn = true, txtBtnConfirm, txtBtnCancel, method) => {
        Swal.fire({
            title: title,
            text: subtitle,
            icon: icon,
            showCancelButton: cancelBtn,
            confirmButtonText: txtBtnConfirm,
            cancelButtonText: txtBtnCancel,
            allowEscapeKey: false,
            allowOutsideClick: false
        })
            .then((result) => {
                if (result.isConfirmed) {
                    method(true);
                }
            })
    }

    const sweetConfirmationUpdate = async (title, subtitle, icon, method, eventTarget = false, cancelBtn = false) => {

        Swal.fire({
            title: title,
            text: subtitle,
            icon: icon,
            showCancelButton: cancelBtn,
            confirmButtonText: "Si, actualizar",
            cancelButtonText: "Cancelar",
            allowEscapeKey: false,
            allowOutsideClick: false
        })
            .then((result) => {
                if (result.isConfirmed) {
                    method(eventTarget, true);
                }
                else {
                    method(eventTarget, 0);
                    Swal.close();
                }
            })
    }

    const sweetDelete = async (title, subtitle, icon, cancelBtn = false, id, method, id_analyte) => {

        Swal.fire({
            title: title,
            text: id,
            icon: icon,
            showCancelButton: cancelBtn,
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar",
            allowEscapeKey: false,
            allowOutsideClick: false,
            customClass: { container: 'my-swal' }
        })
            .then((result) => {
                if (result.isConfirmed) {
                    method(id_analyte, true);
                }
                else {
                    method(id_analyte, false);
                }
            })
    }

    const sweetDoctorDelete = async (title, subtitle, icon, cancelBtn = false) => {
        const result = await Swal.fire({
            title,
            text: subtitle,
            icon,
            showCancelButton: cancelBtn,
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar",
            allowEscapeKey: false,
            allowOutsideClick: false,
            customClass: { container: 'my-swal' },
        });
        return result.isConfirmed;
    };

    const sweetError = async (title, subtitle, icon, cancelBtn = false, data) => {
        Swal.fire({
            title: title,
            text: data.toString(),
            icon: icon,
            showCancelButton: cancelBtn,
            confirmButtonText: "Ok",
            allowEscapeKey: false,
            allowOutsideClick: false
        })
            .then((result) => {
                if (result.isConfirmed) {
                    ////console.log(data);
                }
            })
    }

    const sweetConfirmRepeatExam = async (title, subtitle, icon) => {

        Swal.fire({
            title: title,
            text: subtitle,
            icon: icon,
            showCancelButton: false,
            confirmButtonText: "Ok",
            allowEscapeKey: false,
            allowOutsideClick: false
        })
    }
    //toma

    const sweetConfirmationChangeClient = async (title, subtitle, icon, typeClient, cancelBtn = false, method) => {

        Swal.fire({
            title: title,
            text: subtitle,
            icon: icon,
            showCancelButton: cancelBtn,
            confirmButtonText: "Aceptar",
            cancelButtonText: "Cancelar",
            allowEscapeKey: false,
            allowOutsideClick: false
        })
            .then((result) => {
                if (result.isConfirmed) {
                    method(typeClient, true);
                }
                else {
                    method(typeClient, false);
                }
            })
    }

    const sweetValidDate = async (title, subtitle, icon) => {

        const Toast = Swal.mixin({
            toast: true,
            position: 'center',
            showConfirmButton: false,
            timer: 7000,
            timerProgressBar: false,
            showClass: {
                popup: 'animate__animated animate__fadeInRight'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutRight'
            },
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: icon,
            title: title,
            text: subtitle,
        })           
    }

    const sweetValidcalender = async (title, subtitle, icon) => {

        const Toast = Swal.mixin({
            toast: true,
            position: 'center',
            showConfirmButton: false,
            timer: 7000,
            timerProgressBar: false,
            showClass: {
                popup: 'animate__animated animate__fadeInRight'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutRight'
            },
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: icon,
            title: title,
            text: subtitle,
        })           
    }

    
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });
      
      const sweetDeleteBacterium1 = async (title, subtitle, icon, cancelBtn = false, id, callback) => {
          const result = await swalWithBootstrapButtons.fire({
              title: title,
              text: subtitle,
              icon: icon,
              showCancelButton: cancelBtn,
              confirmButtonText: "Eliminar",
              cancelButtonText: "Cancelar",
              reverseButtons: true,
              allowEscapeKey: false,
              allowOutsideClick: false,
          });
      
          if (result.isConfirmed) {
              callback(id);
              swalWithBootstrapButtons.fire({
                  title: "Eliminación",
                  text: "Guarde los cambios, para que se elimine completamente.",
                  icon: "success"
              });
          } 
          
      };

    

   

    return [createSweet, sweetConfirmation, sweetDelete, sweetError, createSweett, sweetConfirmationUpdate, sweetConfirmRepeatExam, sweetConfirmationChangeClient, sweetConfirmationCancel, sweetValidDate,sweetDeleteBacterium1,sweetDoctorDelete];
}
